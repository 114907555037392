body {
  background-color: #C8BEFD;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.app img {
  width: 386px;
  aspect-ratio: auto 386 / 82;
  height: 82px;
}

a#logo {
  display: block;
  border: none;
  margin: 100px auto 0;
  width: 400px;
  height: 85px;
}

a.link-db {
  display: block;
  width: 300px;
  height: 160px;
  padding: 20px;
  border-radius: 25px;
  box-shadow: 2px 2px #000;
  background-color: #655D93;
  margin: 10px auto;
  text-align: center;
  text-decoration: none;
  font-family: 'Oswald', sans-serif;
  color: #FFF;
  font-size: 21px;
}

a.link-db:hover {
  box-shadow: -2px -2px #000 inset;
  color: #FFF000;
}

p.link-p {
  display: block;
  width: 300px;
  height: 130px;
  padding: 20px;
  border-radius: 25px;
  box-shadow: 2px 2px #000;
  background-color: #655D93;
  margin: 10px auto;
  text-align: center;
  text-decoration: none;
  font-family: 'Oswald', sans-serif;
  color: #FFF;
  font-size: 21px;
}

p.link-p:hover {
  box-shadow: -2px -2px #000 inset;
  color: #FFF000;
}

.link {
  text-decoration: none;
  color: white;
}

.link:hover {
  color: #FFF000;
}